import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/home/circleci/project/node_modules/gatsby-theme-docz/src/base/Layout.js";
import { graphql } from 'gatsby';
import { Paragraph, Heading5 } from '@entur/typography';
import { space } from '@entur/tokens';
import { GridContainer, GridItem } from '@entur/grid';
import { BannerAlertBox } from '@entur/alert';
import { DownloadBrushes } from '~/gatsby-theme-docz/components/DownloadBrushes';
import PageHeader from '~/components/PageHeader';
import { DoDontGroup, DoDontCard } from '~/components/DoDont';
import { ImageDisplay } from '~/components/ImageDisplay';
export const query = graphql`
query grafiskeElementerQuery {
  files: allFile(filter: {sourceInstanceName: {eq: "media"}, relativeDirectory: {glob: "images/grafiske_elementer"}, extension: {eq: "png"}}) {
    images: nodes {
      name
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
}
`;
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component '" + name + "' was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  query,
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">







    <PageHeader mdxType="PageHeader" />
    <h2 {...{
      "id": "typografi"
    }}>{`Typografi`}</h2>
    <p>{`Typografi er et av de bærende grafiske elementene i identiteten. Gjennom bruk av ulike vekter gjør vi innholdet til helten og skaper et lekent og variert uttrykk. Oppsettet tilfører bevegelse og dynamikk til identiteten, og understøtter mangfoldigheten og kontrastene i alle de forskjellige turene Entur tilrettelegger for.`}</p>
    <h3 {...{
      "id": "fargekombinasjoner"
    }}>{`Fargekombinasjoner`}</h3>
    <DoDontGroup mdxType="DoDontGroup">
  <DoDontCard fluidSource={props.data.files.images.find(image => image.name === "Typo-steder-korall-kaviar").childImageSharp.fluid} alt="" variant="none" noPadding mdxType="DoDontCard">
    <Paragraph mdxType="Paragraph">Korall og lys korall på blå bakgrunn.</Paragraph>
  </DoDontCard>
  <DoDontCard fluidSource={props.data.files.images.find(image => image.name === "Typo-steder-korall").childImageSharp.fluid} alt="" variant="none" noPadding mdxType="DoDontCard">
    <Paragraph mdxType="Paragraph">Korall på lys bakgrunn.</Paragraph>
  </DoDontCard>
  <DoDontCard fluidSource={props.data.files.images.find(image => image.name === "Typo-steder").childImageSharp.fluid} alt="" variant="none" noPadding mdxType="DoDontCard">
    <Paragraph mdxType="Paragraph">Farget på lys bakgrunn.</Paragraph>
  </DoDontCard>
  <DoDontCard fluidSource={props.data.files.images.find(image => image.name === "Typo-steder-blue").childImageSharp.fluid} alt="" variant="none" noPadding mdxType="DoDontCard">
    <Paragraph mdxType="Paragraph">Farget på blå bakgrunn.</Paragraph>
  </DoDontCard>
    </DoDontGroup>
    <h3 {...{
      "id": "linjeavstand"
    }}>{`Linjeavstand`}</h3>
    <GridContainer spacing="large" mdxType="GridContainer">
  <GridItem small={12} medium={8} mdxType="GridItem">
    <ImageDisplay fluidSource={props.data.files.images.find(image => image.name === "Typo-linjeavstand").childImageSharp.fluid} alt="" preset="full-width-image" mdxType="ImageDisplay" />
  </GridItem>
  <GridItem small={12} medium={4} mdxType="GridItem">
    <Paragraph mdxType="Paragraph">Mellom ord skal avstanden bestå av mellomrom x 2.</Paragraph>
    <Paragraph mdxType="Paragraph">
      Mellom linjene skal avstanden være 1.1 x punkt størrelsen.
    </Paragraph>
  </GridItem>
    </GridContainer>
    <h2 {...{
      "id": "strek-og-mønster"
    }}>{`Strek og mønster`}</h2>
    <ImageDisplay fluidSource={props.data.files.images.find(image => image.name === "EN_strek_typer").childImageSharp.fluid} alt="Illustrerer de forskjellige strekstilene til Entur" preset="full-width-image" style={{
      marginBottom: space.large
    }} mdxType="ImageDisplay" />
    <p>{`Den røde streken er hentet fra logoen, og danner grunnlaget for strekstilen i Entur.
De forskjellige strekene symboliserer de ulike transportmidlene.
Strekene brukes for seg, i tillegg til sammen med stedsnavn/ulike typer transportmidler.`}</p>
    <p><strong parentName="p">{`NB! I markedsmateriell, skal strekene kun brukes i korall.`}</strong></p>
    <p>{`Strekene er lagret som brushes i en .ai fil og kan lastes ned her:`}</p>
    <DownloadBrushes mdxType="DownloadBrushes" />
    <ImageDisplay fluidSource={props.data.files.images.find(image => image.name === "EN_strek_patterns").childImageSharp.fluid} alt="Illustrerer strekstilen i bruk med stedsnavn, eller ved å kombinere strekstiler" preset="full-width-image" style={{
      marginBottom: space.extraLarge3
    }} mdxType="ImageDisplay" />
    <DoDontGroup mdxType="DoDontGroup">
  <DoDontCard fluidSource={props.data.files.images.find(image => image.name === "EN_strek_linjeavstand").childImageSharp.fluid} alt="" variant="none" noPadding title="Linjeavstand" mdxType="DoDontCard">
    <Paragraph mdxType="Paragraph">
      Avstanden mellom linjene er 2,5 ganger høyden av stedsnavnet.
    </Paragraph>
  </DoDontCard>
  <DoDontCard fluidSource={props.data.files.images.find(image => image.name === "EN_strek_forhold").childImageSharp.fluid} alt="" variant="none" noPadding title="Linjeavstand" mdxType="DoDontCard">
    <Paragraph mdxType="Paragraph">
      20 px avstand mellom linjene i 100 px bredde. 4 enheter pr. 100 px bredde.
    </Paragraph>
  </DoDontCard>
  <DoDontCard fluidSource={props.data.files.images.find(image => image.name === "EN_strek_plassering").childImageSharp.fluid} alt="" variant="none" noPadding title="Plassering" mdxType="DoDontCard">
    <Paragraph mdxType="Paragraph">
      I kombinasjon med linjer skal stedsnavn ikke stå over hverandre. Dette for
      å skape balanse i mønsteret og for å unngå at linjeavstanden ser
      ubalansert ut.
    </Paragraph>
  </DoDontCard>
  <DoDontCard fluidSource={props.data.files.images.find(image => image.name === "EN_strek_str").childImageSharp.fluid} alt="" variant="none" noPadding title="Strektykkelse" mdxType="DoDontCard">
    <Paragraph mdxType="Paragraph">
      Strekenes tykkelse samsarer med linjetykkelsen til vertikalene i Nationale
      Medium.
    </Paragraph>
  </DoDontCard>
    </DoDontGroup>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      